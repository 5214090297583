import { Box, Button, SwipeableDrawer, TextField, Typography } from "@mui/material";
import * as React from "react";
import "./index.css";
import team2 from "../../assets/login_mobile.png";
import arrow from "../../assets/right-arrow.png";
import formTopVector from "../../assets/mobile_login_form.png";
import { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { postSignInAPI, postSignUpAPI } from "../../Pages/action";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SignUpMobile({isSignIn}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popupForm, setPopupForm] = useState(false);
  const [login, setLogin] = useState(true);
  const [mainValues, setMainValues] = useState();
    const [click, setClick] = useState(false);
  const [validationSchema, setSchema] = useState(
    yup.object({
      email: yup.string("Enter your email").required("Email is required"),
      password: yup
        .string("Enter your password")
        .required("Password is required"),
    })
  );

  useEffect(() => {
    const loginInitialValues = { email: "", password: "" };
    const signupInitialValues = {
      email: "",
      name: "",
      password: "",
      confirm_password: "",
    };
    if (window.location.pathname == "/register") {
      setLogin(false);
      setMainValues(signupInitialValues);
      setSchema(
        yup.object({
          email: yup
            .string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
          name: yup.string("Enter your Name").required("Name is required"),
          password: yup
            .string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
          confirm_password: yup
            .string("Enter your Confirm Password")
            .required("Confirm Password is required")
            .oneOf([yup.ref("password")], "Passwords does not match"),
        })
      );
    } else {
      setLogin(true);
      setMainValues(loginInitialValues);
      if(isSignIn){
        handleArrowClick();
      }
    }
  }, []);

  const handleSignUp = async (values) => {
    delete values.confirm_password;
    const payload = JSON.stringify({ ...values, gender: "male" });
    await dispatch(postSignUpAPI(payload,navigate));
  };

  const textFieldHandler = (errors, touched, handleChange) => {
    return login ? (
      <Box style={{ width: "100%" }}>
        <TextField
          id="email"
          label="Email Address"
          variant="standard"
          name="email"
          className="input-mobile"
          onChange={handleChange}
          error={touched?.email && Boolean(errors?.email)}
          helperText={touched?.email && errors?.email}
        />
        <TextField
          id="Password"
          label="Password"
          name="password"
          variant="standard"
          onChange={handleChange}
          className="input-mobile"
          error={touched?.password && Boolean(errors?.password)}
          helperText={touched?.password && errors?.password}
        />
      </Box>
    ) : (
      <Box style={{ width: "100%" }}>
        <TextField
          id="name"
          name="name"
          label="Name"
          variant="standard"
          className="input-mobile"
          onChange={handleChange}
          error={touched?.name && Boolean(errors?.name)}
          helperText={touched?.name && errors?.name}
        />
        <TextField
          id="email"
          name="email"
          label="Email Address"
          variant="standard"
          className="input-mobile"
          onChange={handleChange}
          error={touched?.email && Boolean(errors?.email)}
          helperText={touched?.email && errors?.email}
        />
        <TextField
          id="password"
          name="password"
          label="Password"
          variant="standard"
          className="input-mobile"
          onChange={handleChange}
          error={touched?.password && Boolean(errors?.password)}
          helperText={touched?.password && errors?.password}
        />
        <TextField
          id="confirm_password"
          name="confirm_password"
          label="Confirm Password"
          variant="standard"
          className="input-mobile"
          onChange={handleChange}
          error={touched?.confirm_password && Boolean(errors?.confirm_password)}
          helperText={touched?.confirm_password && errors?.confirm_password}
        />
      </Box>
    );
  };

  const drawerBottom = () => {
    return (
      <SwipeableDrawer
        anchor={"bottom"}
        open={popupForm}
        onClose={() => {
          setPopupForm(false);
        }}
        transitionDuration={{ appear: 1200, enter: 1200, exit: 1000 }}
        className="main-drawer"
        style={{ height: "100%" }}
      >
        <Box className="main-model">
          <Box className="form-top">
            <img
              src={formTopVector}
              alt="form-top"
              className="form-top-vector"
            />
          </Box>
          <Formik
            initialValues={mainValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              login?dispatch(postSignInAPI(values,navigate)):handleSignUp(values);
            }}
          >
            {({ errors, touched, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <Box className="login-form">
                  <Typography
                    variant="h6"
                    component="h6"
                    gutterBottom
                    className="sign-up-mobile"
                  >
                    {login ? "Sign In" : "Sign Up"}
                  </Typography>
                  {textFieldHandler(errors, touched, handleChange)}
                  <Box className="submit-box">
                    <Typography
                      variant="body1"
                      component="p"
                      className="submit-text "
                    >
                      You're all{" "}
                      <span style={{ color: "#d9b75d" }}>set up!</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      className="submit-text "
                    >
                      {login
                        ? "Don't have an account? "
                        : "I'm Already member! "}
                      <Typography
                        variant="body1"
                        component="a"
                        className="signup-btn submit-text"
                        href={login ? "/register" : "/login"}
                      >
                        {login ? "Signup for " : "Signin "}
                        <span style={{ color: "#d9b75d" }}>
                          {login ? "free" : "here"}
                        </span>
                      </Typography>
                    </Typography>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#303747", margin: "15px 0px" }}
                      type="submit"
                    >
                      Complete
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </SwipeableDrawer>
    );
  };

  const handleArrowClick = () => {
    setClick(true)
    setTimeout(() => {
      setPopupForm(true);
    }, 1100);
  }

  return (
    <Box className="signUpPage">
      {drawerBottom()}
      <Box className="headings">
        <Box className="img-box">
          <img src={team2} alt="team" className="main-img" />
        </Box>
        <Typography
          component={"h3"}
          variant="h3"
          fontSize="23px"
          style={{ padding: "10px 20px 0px 20px" }}
        >
          Join and <span style={{ color: "#d9b75d" }}>earn</span> money online.
        </Typography>
        <Typography
          component={"p"}
          variant="p"
          style={{ fontSize: "14px", padding: "20px" }}
        >
          Watch YouTube videos and earn money online with MoneyBucks. Join our
          platform and start earning today!
        </Typography>
      </Box>
      <Box className="signup-buttons">
        <Box
          className="right-side-arrow"
          onClick={handleArrowClick}
        >
          <img src={arrow} alt="team" className={!click?"arrow-png":"arrow-png arrow-png-active"} />
        </Box>
      </Box>
    </Box>
  );
}
export default SignUpMobile;
