import React from "react";
const TermsConditions = () => {
    return (
        <div className=" mx-4 md:mx-16 text-left text-[#484656] ">
            <h1 className="text-4xl mb-2 sm:text-4xl md:text-4xl lg:text-5xl 2xl:text-5xl lg:text-start font-extrabold ">Terms & Conditions</h1>

            <p className="mb-2">
                MoneyBucks.com ("Website") is a Cost Per Referral (CPR) website, which is owned and operated by MoneyBucks LLC ("We" or "Company" or "Our"), a limited liability company registered and organized under the applicable laws in the State of Nevada, United States of America ("U.S.A."). By registering or using any of the services provided through or in connection with this Website, the users ("You" or "Your") hereby agree to be legally bound by the provisions contained in this Terms of Service Agreement ("Agreement"). Before becoming a member of this Website by completing the online registration process, it is absolutely vital to read, understand and agree to the provisions contained herein. If you find any of these provisions unacceptable, you acknowledge that your sole remedy shall be to cease all the current as well as future usage of this Website with immediate effect. This Agreement is subject to revisions, modifications or alterations at any time without any prior or post notice to anyone. Such revisions, modifications or alterations shall come into effect as soon as they are posted on this Website. Your use of this Website signifies your implied acceptance of such revised, modified or altered Agreement.
            </p>
            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Referrals</h4>
            <p className="mb-2">
                Registration is free, you have to refer new members via your referral link and complete tasks to earn. There is no limit on how many people you can refer.
            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Privacy/Spam</h4>
            <p className="mb-2">
                We do not believe in spam, and we are very strict on the handling of your information. We will never distribute your personal information to 3rd party services. The only emails you may receive are periodic emails with information about updates and issues with the site as well as some limited offers with related products or services that may interest you. You automatically agree to subscribe to this newsletter, but each e-mail there will exist an “unsubscribe” option.
            </p>

            {/* 
            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Inactive Accounts</h4>
            <p className="mb-2">
                If your account has been inactive for more than 15 days, your earnings will be cleared and it will be deleted.
            </p> */}

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Payouts</h4>
            <p className="mb-2">
                You can request your payment when your account balance has reached at 'Payment Threshold'. Payments are sent as a pre-loaded electronic fund transfer. You will receive the fund to your e-mail address/bank account and you can then easily cash it out via Paypal, Paytm, Bank Transfer, Phonepe, etc. Payments are issued at the end of each week for the earnings of the previous week (NET 7). However, we need to verify your account before you can receive the funds to your payment account. The verification process begins as soon as you submit your first payment request. Your account must be verified in order to receive a payment. All earnings listed and/or represented by MoneyBucks.com are estimated value(s), actual value(s) subject to change with or without notice.


            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Termination of Membership</h4>
            <p className="mb-2">
                MoneyBucks may terminate your membership at any time immediately with or without cause. Your right to use your membership immediately ceases upon termination. MoneyBucks may terminate this agreement and your membership with or without prior notice. Termination of an account may lead to full or partial loss of the payouts accumulated prior to termination.

            </p>
            <p className="mb-2">
                You may cancel your membership at any time by deleting your account in account section and you will be deleted from the list of the opted-in MoneyBucks Recipients.
            </p>
            <p className="mb-2">
                We reserve the right to terminate or disable, at our sole discretion, any Service membership if we reasonably believe such Member has violated or acted inconsistently with the rules of the Service or this Agreement.
            </p>
            <p className="mb-2">
                You shall comply with all laws, rules, and regulations that are applicable to you. You acknowledge that you may only participate in the Service if and to the extent that such participation is permitted by such laws, rules, and regulations. We may refuse to enroll you, or to restrict, modify, or terminate your participation in the Service, without liability to you or any other party, if you violate any law, rule, or regulation, or if your participation in the Service could violate any law, rule, or regulation.

            </p>
            <p className="mb-2">
                MoneyBucks shall be the sole determiner in cases of suspected abuse, fraud, or violation of its rules and any decision it makes relating to termination of membership shall be final and binding. Should you object to any of the terms and conditions of this Agreement, or any subsequent modifications thereto, or become dissatisfied with the Service, your only recourse is to immediately discontinue participation in the Service and properly terminate your membership.

            </p>



            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Invalid Checks</h4>
            <p className="mb-2">
                Invalid click activity includes any clicks or impressions that may artificially inflate an advertiser's costs or a publisher's earnings. Invalid activity covers intentionally fraudulent traffic as well as accidental clicks. Invalid activity includes, but is not limited to:
            </p>
            <p className="mb-2">
                Clicks or impressions generated by members clicking their own live link or ad Repeated ad clicks or impressions generated by one or more users, members encouraging clicks on their ads (examples may include: any language encouraging users to click on ads, ad implementations that may cause a high volume of accidental clicks, etc.) Automated clicking tools or traffic sources, robots, or other deceptive software.

            </p>
            <p className="mb-2">
                We understand that a third party may generate invalid activity on your ads without your knowledge or permission. However, ultimately it is your responsibility as the member to ensure that the activity on your ads is valid.

            </p>
            <p className="mb-2">
                Clicks on our ads must result from genuine user interest, and any method that artificially generates clicks or impressions is strictly prohibited by our program policies. If we observe high levels of invalid activity on your account, we may suspend or disable the account to protect our advertisers and users. Due to invalid clicks.

            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Cheating</h4>
            <p className="mb-2">
                With our tough anti-cheat system, it is extremely difficult to cheat. If someone is caught cheating or simply attempting to cheat, we have the right to forfeit all earnings and possibly even ban the user from our network. Cheating would include any of the following:
            </p>
            <ul style={{ listStyle: "disc" }} className="mb-2 ml-4">
                <li>
                    Interfering with our system to prevent optimum security and/or reliability.
                </li>
                <li>
                    Creating any type of emulator, or a program to emulate fake traffic.

                </li>
                <li>
                    Sending fake traffic using hacking-related methods, proxies, traffic exchange networks, etc.
                </li>
                <li>
                    You must not share our links in adult content websites.
                </li>
                <li>
                    You must not spam in other websites or communities.
                </li>
                <li>
                    You must not use this website with a VPN or proxy.
                </li>
                <li>
                    You must not request a payment without reaching the minimum payment threshold (₹5000).
                </li>
                <li>
                    You must read our Fraud Policy before starting any works/jobs in MoneyBucks.com.
                </li>

                <li>
                    You must complete user verification process to recive payments.
                </li>
            </ul>


            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Earnings Disclaimer</h4>
            <p className="mb-2">
                Any Earning figures mentioned in the site are only estimations of what we believe you could earn. We cannot and do not make any guarantees about your ability to get results or earn any money with our ideas, information, tools or strategies. Nothing on any of our website page is a promise or guarantee of results or future earnings.

            </p>
            <p className="mb-2">
                We have no way of knowing how well you will do, as we do not know about you and your skills or practices. Therefore we do not guarantee or imply that you will earn money or get rich, you must accept the risk of not doing well. Nor are we responsible for any of your efforts.
            </p>
            <p className="mb-2">
                Any Results or Financial Performance you may see on our website or within any of our content are not typical. We never guarantee that those results have been achieved. The Earning Results may vary from one person to another.
            </p>
            <p className="mb-2">
                This is not a get rich quick scheme. Results may vary, as with any business opportunity, you could make more or less. Success in any business opportunity is a result of your ability, understanding, effort, hard work, dedication and a variety of other factors. No express or implied guarantees of income are made when joining us.
            </p>
            <p className="mb-2">
                You agree that MoneyBucks.com is not responsible for the success or failure of your Earnings relating to any information presented by our website, or our company, product, services, or opportunity.

            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Subject to change</h4>
            <p className="mb-2">
                This agreement can change. Although we have the right to change this agreement without any prior notice to our members, we will send out a notice to all of our members the new terms and/or terms that have changed, but we are not to held liable if the email is not sent or fails to be received by the members. If for any reason a member does not accept the changes, they will need to contact us, and their account will be removed. Your continued use of MoneyBucks constitutes an affirmative acknowledgement by you of the Terms and Conditions and any subsequent modifications made to it and your agreement to abide and be bound by the Terms and Conditions document in its modified form. We reserve the right to modify or discontinue the Service with or without notice to you. We shall not be liable to you or any third party should we exercise our right to modify or discontinue the Service.


            </p>



        </div>
    )
}
export default TermsConditions;