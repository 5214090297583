import React from "react";
const PrivacyPolicy = () => {
    return (
        <div className=" mx-4 md:mx-16 text-left text-[#484656] ">
            <h1 className="text-4xl mb-2 sm:text-4xl md:text-4xl lg:text-5xl 2xl:text-5xl lg:text-start font-extrabold ">Privacy Policy</h1>

            <p className="mb-2">It is MoneyBucks’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to MoneyBucks.com (hereinafter, "us", "we", or "MoneyBucks.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Site Visitors</h4>
            <p className="mb-2">Like most website operators, MoneyBucks collects non-personally-identifying information of the sort that web browsers
                and servers typically make available, such as the browser type, language preference, referring site, and the date
                and time of each visitor request. MoneyBucks’s purpose in collecting non-personally identifying information is to
                better understand how MoneyBucks’s visitors use its website. From time to time, MoneyBucks may release non-personally-identifying
                information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
            <p className="mb-2">MoneyBucks also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on http://MoneyBucks.com blog posts. MoneyBucks only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">User Information</h4>
            <p className="mb-2">Certain visitors to MoneyBucks’s websites choose to interact with MoneyBucks in ways that require MoneyBucks to gather personally-identifying information. The amount and type of information that MoneyBucks gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at http://MoneyBucks.com to provide a username and email address.</p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Advertisements</h4>
            <p className="mb-2">
                Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by MoneyBucks and does not cover the use of cookies by any advertisers.
            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Protection of User Information</h4>
            <p className="mb-2">
                MoneyBucks discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on MoneyBucks’s behalf or to provide services available at MoneyBucks’s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using MoneyBucks’s website, you consent to the transfer of such information to them. MoneyBucks will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, MoneyBucks discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when MoneyBucks believes in good faith that disclosure is reasonably necessary to protect the property or rights of MoneyBucks, third parties or the public at large.
            </p>
            <p className="mb-2">
                If you are a registered user of http://MoneyBucks.com and have supplied your email address, MoneyBucks may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with MoneyBucks and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. MoneyBucks takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Privacy Policy Changes</h4>
            <p className="mb-2">
                Although most changes are likely to be minor, MoneyBucks may change its Privacy Policy from time to time, and in MoneyBucks’s sole discretion. MoneyBucks encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.

            </p>

            <h4 className="text-2xl mb-2 sm:text-2xl md:text-2xl lg:text-5xl 2xl:text-2xl lg:text-start font-bold ">Security</h4>
            <p className="mb-2">
                The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.

            </p>


        </div>
    )
}
export default PrivacyPolicy;